import { useEffect, useState } from "react";
import { getLang } from "../utils/getLang";

const useRealtimeDate = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    // update date every 15 seconds
    // because we never know when the user will open the page
    // and we want to make sure the date is always up to date
    const timer = setInterval(() => {
      setTime(new Date());
    }, 15 * 1000);
    return () => {
      clearInterval(timer);
    };
  });
  const timeDisplay = Intl.DateTimeFormat(getLang(), {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(time);
  const dateDisplay = Intl.DateTimeFormat(getLang(), {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(time);

  return {
    timeDisplay,
    dateDisplay,
  };
};

export default useRealtimeDate;
