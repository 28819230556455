import { ResumeTimelineItem } from "./types";
import CodingImg from "../../assets/images/coding.svg?react";
import SharedWorkspace from "../../assets/images/shared-workspace.svg?react";
import Entrepreneurial from "../../assets/images/programming.svg?react";
import WorkingInOffice from "../../assets/images/working-in-office.svg?react";
import Professional from "../../assets/images/professional.svg?react";
import Freelance from "../../assets/images/freelance.svg?react";
import TechLeap from "../../assets/images/tech-leap.svg?react";
import CareerProgress from "../../assets/images/career-progress.svg?react";
import Solution from "../../assets/images/solution.svg?react";

const resumeData: ResumeTimelineItem[] = [
  {
    title: "Curiosity Unleashed",
    date: new Date("2016-04-01"),
    description: (
      <>
        My coding journey began in April 2016, driven by a fascination with
        website mechanics. I embarked on learning HTML, CSS, and JavaScript
        through Udemy courses, and quickly embraced the power of Git and GitHub
        for collaborative development.
      </>
    ),
    illustration: <CodingImg />,
  },

  {
    title: "Freelance Debut",
    date: new Date("2017-06-01"),
    description: (
      <>
        My first freelancing venture was a project from my professor at his
        media agency,{" "}
        <a href="https://propeller.de/" target="__blank">
          Propeller
        </a>
        . I was tasked with creating a Typo 3 theme for{" "}
        <a href="https://biesdorf-kram.de/">Biesdorf, Kram & Partner</a>.
      </>
    ),
    illustration: <SharedWorkspace />,
  },

  {
    title: "Entrepreneurial Spirit",
    date: new Date("2017-09-01"),
    description: (
      <>
        I founded{" "}
        <a href="https://www.facebook.com/mihapetransfer/" target="__blank">
          Mihape Transfer
        </a>
        , a fintech startup in Indonesia, facilitating global money transfers.
        This venture taught me valuable lessons in business, marketing, and the
        complex legalities of fintech. The company was then closed in early 2018
        after having served roughly few dozen customers during the MVP stage.
      </>
    ),
    illustration: <Entrepreneurial />,
  },

  {
    title: "Academic and Professional Growth",
    date: new Date("2018-12-01"),
    description: (
      <>
        My role as an associate student employee at{" "}
        <a href="https://www.aws-institut.de/" target="__blank">
          August-Wilhelm Scheer Institut
        </a>{" "}
        was a formative experience, where I developed a web application for
        managing renewable energy, leveraging Angular, Node.js, and MongoDB.
      </>
    ),
    illustration: <WorkingInOffice />,
  },

  {
    title: "Professional Milestone",
    date: new Date("2019-02-01"),
    description: (
      <>
        At{" "}
        <a href="https://www.agfahealthcare.com/" target="__blank">
          Agfa Healthcare
        </a>
        , I transitioned from student employee to professional software
        developer under the mentorship of seasoned experts. I contributed to a
        project managing software packages on the cloud for OrbisOS.
      </>
    ),
    illustration: <Professional />,
  },

  {
    title: "Freelance Resurgence",
    date: new Date("2020-05-01"),
    description: (
      <>
        The pandemic presented an opportunity to revisit freelancing. During
        this time, I engaged in diverse projects, from developing WordPress
        templates to crafting web applications for IoT devices, honing my skills
        in autonomous work and client communication.
      </>
    ),
    illustration: <Freelance />,
  },

  {
    title: "Tech Industry Leap",
    date: new Date("2021-02-01"),
    description: (
      <>
        My journey continued at{" "}
        <a href="https://www.linkedin.com/company/nexocraft" target="__blank">
          Nexocraft
        </a>
        , where I developed IoT applications for the plastic industry using
        Angular, Spring Boot, and a suite of modern development tools. The
        experience ended as the company closed in 2022.
      </>
    ),
    illustration: <TechLeap />,
  },

  {
    title: "Corporate Success",
    date: new Date("2022-10-01"),
    description: (
      <>
        At{" "}
        <a href="https://zalando.de/" target="__blank">
          Zalando
        </a>
        , I scaled new heights as a Frontend Engineer, developing B2B
        applications for top-tier clients like Adidas and Nike. My experience
        broadened in rollouts, diverse teamwork, and enterprise-level
        development.
      </>
    ),
    illustration: <CareerProgress />,
  },

  {
    title: "Current Chapter",
    date: new Date("2023-03-01"),
    description: (
      <>
        I thrive as a Senior Software Engineer at{" "}
        <a href="https://www.ampero.ai/" target="__blank">
          Ampero by BayWa r.e.
        </a>
        , leading projects that optimize portfolio management for renewable
        energies. My toolkit includes React, NextJS, Spring Boot and more. As I
        continuously seek new challenges, I'm open to any opportunities that
        come my way.
      </>
    ),
    illustration: <Solution />,
  },
];

export default resumeData;
