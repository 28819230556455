const QUOTES = [
  {
    title: "RizkiOS",
    subtitle: "Life's too short to use Windows",
  },
  {
    title: "Hello",
    subtitle: "Seek knowledge from the cradle to the grave",
  },
  {
    title: "Smile",
    subtitle: "It's the easiest charity",
  },
  {
    title: "Welcome",
    subtitle: "Peace be upon you",
  },
];

export default QUOTES;
