import { aboutData } from "./aboutData";
import "./About.scss";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

const About = () => {
  const [selected, setSelected] = useState<string>();
  const [showSelected, setShowSelected] = useState<string>();

  const heroClass = selected ? "about-page__hero hidden" : "about-page__hero";
  const selectCategory = (category: string) => {
    setSelected((prev) => (prev === category ? undefined : category));
  };

  const selectedDebounce = debounce((selectedValue) => {
    setShowSelected(selectedValue);
  }, 499);

  useEffect(() => {
    if (selected === undefined) {
      setShowSelected(undefined);
      return;
    }
    selectedDebounce(selected);
  }, [selected, selectedDebounce]);

  return (
    <div className="browser-content about-page">
      {Object.entries(aboutData).map(([category]) => {
        const isSelected = selected === category;
        const skillCategoryClass = isSelected
          ? "about-page__skill-category selected"
          : "about-page__skill-category";
        return (
          <div
            className={skillCategoryClass}
            key={category}
            onClick={() => selectCategory(category)}
          >
            <h2 className="about-page__skill-category__title">
              {category.toUpperCase()}
            </h2>
          </div>
        );
      })}

      <div className={heroClass}>
        <h1 className="about-page__hero__title">
          Ready to check out the engine under the hood?
        </h1>
        <p className="about-page__hero__subtitle">
          I quickly found my passion in knitting together complex codes into
          beautiful, efficient applications. Here's what I use throughout my
          journey.
        </p>
      </div>

      {selected && showSelected && (
        <div className="about-page__selected-category" key={showSelected}>
          <h2 className="about-page__selected-category__title">
            {showSelected.toUpperCase()}
          </h2>
          {aboutData[showSelected].map(
            ({ subcategory, iconSrc, description }) => (
              <>
                <div
                  key={`${subcategory}-description`}
                  className="about-page__selected-category__desc"
                >
                  <h3 className="about-page__selected-category__desc__subtitle">
                    {subcategory}
                  </h3>
                  {iconSrc.length > 1 && (
                    <div className="about-page__selected-category__desc__icons">
                      {iconSrc.map((imgAttr) => (
                        <img key={imgAttr.title} {...imgAttr} />
                      ))}
                    </div>
                  )}
                  {description && (
                    <p className="about-page__selected-category__desc__description">
                      {description}
                    </p>
                  )}
                </div>
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default About;
