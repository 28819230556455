import { useEffect, useRef } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOutsideClickListener(
  ref: ReturnType<typeof useRef<HTMLElement | null>>,
  onOutsideClick: () => void,
  excludeRefs?: Array<ReturnType<typeof useRef<HTMLElement | null>>>
) {
  useEffect(() => {
    /**
     * Call the onOutsideClick callback if the user clicks outside of the ref or any of the excludeRefs
     */
    function handleClickOutside(event: Event) {
      if (excludeRefs) {
        for (let i = 0; i < excludeRefs.length; i++) {
          if (
            excludeRefs[i].current &&
            excludeRefs[i].current?.contains(event.target as Node)
          ) {
            return;
          }
        }
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
        return;
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick, excludeRefs]);
}
