import { useEffect, useState } from "react";
import BrowserWindow from "../BrowserWindow/BrowserWindow";
import apps, { AppType, AppTypes } from "./apps";
import "./WindowManager.scss";
import { groupBy } from "lodash";

const WindowManager = () => {
  const [windowManager, setWindowManager] = useState<AppType[]>([
    AppTypes.HOME,
  ]);
  const [currentFocus, setCurrentFocus] = useState(AppTypes.NONE);

  const openAppOrCloseApp = (app: AppType) => {
    const indexApp = windowManager.indexOf(app);

    // If the app is already open, focus on it
    if (indexApp !== -1 && currentFocus !== app) {
      focusApp(windowManager[indexApp]);
      return;
    }

    // If the app is already open and focused, close it
    if (indexApp !== -1 && currentFocus === app) {
      closeOrMinimizeApp(app);
      return;
    }

    // If the app is not open, open it
    setWindowManager([...windowManager, app]);
    setCurrentFocus(app);
  };

  const focusApp = (app: AppType) => {
    const indexApp = windowManager.indexOf(app);

    if (indexApp !== -1) {
      setCurrentFocus(app);
    }
  };

  const closeOrMinimizeApp = (app: AppType) => {
    const indexApp = windowManager.indexOf(app);

    if (indexApp !== -1) {
      const newWindowManager = windowManager.filter((item) => item !== app);
      setWindowManager(newWindowManager);
      setTimeout(() => {
        setCurrentFocus(
          newWindowManager.length > 0
            ? newWindowManager[newWindowManager.length - 1]
            : AppTypes.NONE
        );
      }, 200);
    }
  };

  const getAllOpenedApps = () => {
    const mapOfAppTypeToApps = groupBy(apps, "type");

    return windowManager
      .map((appType) => {
        const app = mapOfAppTypeToApps[appType];

        if (app && app.length > 0) {
          return app[0];
        }
      })
      .filter((app) => !!app);
  };

  // Focus whatever window is open when first load
  useEffect(() => {
    if (windowManager.length > 0) {
      setCurrentFocus(windowManager[windowManager.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {getAllOpenedApps().map((app) => (
        <BrowserWindow
          key={app.title}
          title={app.title}
          address={app.address}
          open={windowManager.indexOf(app.type) !== -1}
          onClose={() => closeOrMinimizeApp(app.type)}
          onClick={() => focusApp(app.type)}
          focused={currentFocus === app.type}
        >
          {app.children}
        </BrowserWindow>
      ))}
      <div className="dock">
        {apps.map((app) => {
          const appIcon =
            app.type === AppTypes.CONTACT ? (
              <a href={app.address} target="_blank" rel="noreferrer">
                {app.icon}
              </a>
            ) : (
              app.icon
            );

          return (
            <div
              key={app.title}
              onClick={(e) => {
                e.stopPropagation();
                if (app.type !== AppTypes.CONTACT) {
                  openAppOrCloseApp(app.type);
                }
              }}
              className={`dock-item ${app.className}`}
            >
              {appIcon}
              <p className="dock-item__title">{app.title}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default WindowManager;
