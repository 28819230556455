import "./Home.scss";

const Home = () => {
  return (
    <div className="browser-content home">
      <div className="home__hero-image"></div>
      <div className="home__glass"></div>
      <div className="home__copy">
        <h3 className="home__copy__title">
          Hey there! I'm Rizki, a software engineer based in Trier, Germany.
        </h3>
        <p className="home__copy__subtitle">
          I craft sleek, powerful software solutions that buzz with energy and
          innovation. Dive into my world and let’s build something amazing
          together!
        </p>
      </div>
      <div className="home__titles">
        <p className="home__titles__item">Creative Developer</p>
        <p className="home__titles__item">Software Engineer</p>
        <p className="home__titles__item">Innovative Leader</p>
        <p className="home__titles__item">Tech Visionary</p>
      </div>
    </div>
  );
};

export default Home;
