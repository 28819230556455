import { GiShinyApple } from "react-icons/gi";
import "./MenuBar.scss";
import { useContext, useRef, useState } from "react";
import useOutsideClickListener from "../../hooks/useOutsideClickListener";
import { FocusDeviceContext } from "../../providers/FocusDeviceProvider";
import useRealtimeDate from "../../hooks/useRealtimeDate";

const MenuBar = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const logoDropdownRef = useRef<HTMLDivElement | null>(null);
  const logoMenuRef = useRef<HTMLDivElement | null>(null);
  const { timeDisplay, dateDisplay } = useRealtimeDate();

  const { setFocusPoint, setIsMacbookOn } = useContext(FocusDeviceContext);

  useOutsideClickListener(
    logoDropdownRef,
    () => {
      setIsDropdownVisible(false);
    },
    [logoMenuRef]
  );

  const handleSleep = () => {
    setIsMacbookOn(false);
    setFocusPoint(undefined);
  };

  return (
    <div className="menu-bar">
      <div className="menu-bar__menu">
        <div
          ref={logoMenuRef}
          onClick={(e) => {
            e.stopPropagation();
            e.currentTarget.focus();
            setIsDropdownVisible(!isDropdownVisible);
          }}
          className="menu-bar__menu__logo"
        >
          <GiShinyApple />
        </div>
        <div className="menu-bar__menu__item">File</div>
        <div className="menu-bar__menu__item">Edit</div>
        <div className="menu-bar__menu__item">View</div>
        <div className="menu-bar__menu__item">Help</div>
      </div>
      <div className="menu-bar__datetime">
        <span className="menu-bar__time__date">{dateDisplay}</span>{" "}
        <span className="menu-bar__time__time">{timeDisplay}</span>
      </div>
      <div
        ref={logoDropdownRef}
        className={
          "menu-bar__logo-dropdown" + (isDropdownVisible ? " visible" : "")
        }
      >
        <div className="menu-bar__logo-dropdown__item disabled">
          About this Person
        </div>
        <hr />
        <div className="menu-bar__logo-dropdown__item" onClick={handleSleep}>
          Sleep
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
