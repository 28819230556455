import React from "react";
import { BsPersonVcardFill } from "react-icons/bs";
// import { HiPresentationChartLine } from "react-icons/hi";
import { MdWorkHistory } from "react-icons/md";
import Home from "../Home/Home";
// import UnderConstruction from "../UnderConstruction/UnderConstruction";
import { HiMiniHome } from "react-icons/hi2";
import Resume from "../Resume/Resume";
import { FaLinkedin, FaSquareGithub } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import About from "../About/About";

export type AppType = string;
export const AppTypes = {
  HOME: "HOME",
  ABOUT: "ABOUT",
  RESUME: "RESUME",
  PROJECT: "PROJECT",
  NONE: "NONE",
  CONTACT: "CONTACT",
};

type App = {
  title: string;
  address: string;
  icon: React.ReactElement;
  type: AppType;
  className: string;
  children?: React.ReactNode;
};

const linkedinStyle = {
  color: "#266cf1",
};
const githubStyle = {
  color: "#24292E",
};
const gmailStyle = {
  color: "#D14836",
};

const sizeProps = {
  size: 40,
};

const mailTo = () => {
  const mail = {
    email: "fikriansyah@mrizki.com",
    subject: "I have opportunity for you!",
    body: "Hi Rizki, Do you have a chance to talk about a new opportunity?",
  };

  return `mailto:${mail.email}?subject=${mail.subject}&body=${mail.body}`;
};

const apps: App[] = [
  {
    title: "Home",
    address: "http://mrizki.com/home",
    icon: <HiMiniHome {...sizeProps} />,
    type: AppTypes.HOME,
    className: "home",
    children: <Home />,
  },
  {
    title: "About",
    address: "About Me!",
    icon: <BsPersonVcardFill {...sizeProps} />,
    type: AppTypes.ABOUT,
    className: "about",
    children: <About />,
  },
  {
    title: "Resume",
    address: "Work Experience and Education",
    icon: <MdWorkHistory {...sizeProps} />,
    type: AppTypes.RESUME,
    className: "resume",
    children: <Resume />,
  },
  // {
  //   title: "Projects",
  //   address: "Projects I've worked on",
  //   icon: <HiPresentationChartLine {...sizeProps} />,
  //   type: AppTypes.PROJECT,
  //   className: "project",
  //   children: <UnderConstruction title="Projects" />,
  // },
  {
    title: "LinkedIn",
    address: "https://www.linkedin.com/in/rizkifikri/",
    icon: <FaLinkedin {...sizeProps} style={linkedinStyle} />,
    type: AppTypes.CONTACT,
    className: "linkedin",
  },

  {
    title: "Personal",
    address: "https://www.github.com/klovack",
    icon: <FaSquareGithub {...sizeProps} style={githubStyle} />,
    type: AppTypes.CONTACT,
    className: "github",
  },

  {
    title: "Work",
    address: "https://www.github.com/rizki-ampero",
    icon: <FaSquareGithub {...sizeProps} style={githubStyle} />,
    type: AppTypes.CONTACT,
    className: "github",
  },

  {
    title: "Mail",
    address: mailTo(),
    icon: <BiLogoGmail {...sizeProps} style={gmailStyle} />,
    type: AppTypes.CONTACT,
    className: "email",
  },
];

export default apps;
