import { useState } from "react";
import { FaCircle } from "react-icons/fa6";
import resumeData from "./data";
import "./Resume.scss";

const Resume = () => {
  const [currentDesc, setCurrentDesc] = useState(resumeData.length - 1);

  return (
    <div className="browser-content resume">
      <div className="resume__header">
        <h1 className="resume__header__title">
          Strap in and let me take you on a tour of my journey through the tech
          landscape!
        </h1>
      </div>
      <div className="resume__timeline">
        {resumeData.map((item, index) => {
          const isCurrent = index === currentDesc;
          const isNearCurrent = Math.abs(index - currentDesc) < 2;
          let itemClassName = isCurrent
            ? "resume__timeline__item selected"
            : "resume__timeline__item";
          itemClassName += !isCurrent && isNearCurrent ? " near-selected" : "";

          return (
            <div className={itemClassName} key={index}>
              <span className="resume__timeline__item__year-month">
                {item.date.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                })}
              </span>
              <button
                className="resume__timeline__item__marker"
                onPointerEnter={() => setCurrentDesc(index)}
              >
                <FaCircle size={20} />
              </button>
            </div>
          );
        })}
      </div>
      <div className="resume__detail">
        <h2 className="resume__detail__title">
          {resumeData[currentDesc].title}
        </h2>
        <p className="resume__detail__description">
          {resumeData[currentDesc].description}
        </p>
        <div className="resume__detail__illustration">
          {resumeData[currentDesc].illustration}
        </div>
      </div>
    </div>
  );
};

export default Resume;
