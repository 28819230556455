import { createContext, PropsWithChildren, useContext, useState } from "react";
import { FocusPoint } from "../PortfolioControl";

type FocusDeviceProviderProps = PropsWithChildren & {};

export type FocusDeviceContextType = {
  isMacbookOn: boolean;
  setIsMacbookOn: (isMacbookOn: boolean) => void;

  isMacbookOpen: boolean;
  setIsMacbookOpen: (isMacbookOpen: boolean) => void;

  /**
   * @deprecated Use `useBounds` from `@react-three/drei` instead.
   */
  focusPoint: FocusPoint | undefined;
  /**
   * @deprecated Use `useBounds` from `@react-three/drei` instead.
   */
  setFocusPoint: (focusPoint: FocusPoint | undefined) => void;
};

export const FocusDeviceContext = createContext<FocusDeviceContextType>({
  isMacbookOn: false,
  setIsMacbookOn: () => {},
  focusPoint: undefined,
  setFocusPoint: () => {},
  isMacbookOpen: false,
  setIsMacbookOpen: () => {},
});

const FocusDeviceProvider = ({ children }: FocusDeviceProviderProps) => {
  const [isMacbookOn, setIsMacbookOn] = useState(false);
  const [isMacbookOpen, setIsMacbookOpen] = useState(false);
  const [focusPoint, setFocusPoint] = useState<FocusPoint | undefined>();

  return (
    <FocusDeviceContext.Provider
      value={{
        isMacbookOn,
        setIsMacbookOn,
        focusPoint,
        setFocusPoint,
        isMacbookOpen,
        setIsMacbookOpen,
      }}
    >
      {children}
    </FocusDeviceContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFocusDevice = () => {
  return useContext(FocusDeviceContext);
};

export default FocusDeviceProvider;
