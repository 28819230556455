import React, { useEffect, useRef } from "react";
import "./BrowserWindow.scss";

type BrowserWindowProps = {
  children?: React.ReactNode;
  title: string;
  address: string;
  focused?: boolean;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onClick?: () => void;
  windowOffset?: number;
};

let numberOfWindows = 0;

const BrowserWindow = ({
  children,
  title,
  address,
  open,
  onOpen,
  onClose,
  onClick,
  focused,
  windowOffset = 10,
}: BrowserWindowProps) => {
  const browserWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!browserWindowRef.current) {
      return;
    }
    numberOfWindows++;

    browserWindowRef.current.style.setProperty(
      "left",
      `calc((50% - (100% - 80px) / 2) + ${windowOffset * numberOfWindows}px)`
    );
    browserWindowRef.current.style.setProperty(
      "bottom",
      80 - windowOffset * numberOfWindows + "px"
    );

    return () => {
      numberOfWindows--;
    };
  }, []);

  useEffect(() => {
    if (!browserWindowRef.current) {
      return;
    }

    if (open) {
      browserWindowRef.current.classList.remove("closed");
      onOpen?.();
    } else {
      closeWindow();
    }
  }, [open]);

  useEffect(() => {
    if (!browserWindowRef.current) {
      return;
    }

    if (focused) {
      browserWindowRef.current.classList.add("focused");
    } else {
      browserWindowRef.current.classList.remove("focused");
    }
  }, [focused]);

  const closeWindow = () => {
    if (!browserWindowRef.current) {
      return;
    }

    browserWindowRef.current.classList.add("closed");
    onClose?.();
  };

  const toggleZoom = () => {
    if (!browserWindowRef.current) {
      return;
    }

    browserWindowRef.current.classList.toggle("zoomed");
  };

  return (
    <div onClick={onClick} className="browser-window" ref={browserWindowRef}>
      <div className="header">
        <div className="buttons">
          <button onClick={closeWindow} className="close"></button>
          <button onClick={closeWindow} className="minimize"></button>
          <button onClick={toggleZoom} className="zoom"></button>
        </div>
        <div className="title">{title}</div>
      </div>
      <div className="content">
        <div className="address-bar">
          <div className="address">{address}</div>
        </div>
        <div className="content-body">{children}</div>
      </div>
    </div>
  );
};

export default BrowserWindow;
