import TypescriptSrc from "../../assets/images/skill-icons/typescript.png";
import JavascriptSrc from "../../assets/images/skill-icons/javascript.png";
import HtmlSrc from "../../assets/images/skill-icons/html-5.png";
import CssSrc from "../../assets/images/skill-icons/css3.png";
import ReactSrc from "../../assets/images/skill-icons/react.png";
import AngularSrc from "../../assets/images/skill-icons/angular.png";
import JavaSrc from "../../assets/images/skill-icons/java-coffee-cup-logo.png";
import PythonSrc from "../../assets/images/skill-icons/python.png";
import NodejsSrc from "../../assets/images/skill-icons/nodejs.png";
import GolangSrc from "../../assets/images/skill-icons/golang.png";
import ExpressSrc from "../../assets/images/skill-icons/express.png";
import SpringBootSrc from "../../assets/images/skill-icons/spring-boot.png";
import FastAPISrc from "../../assets/images/skill-icons/fastapi.png";
import MongoDbSrc from "../../assets/images/skill-icons/mongodb.png";
import PostgresSrc from "../../assets/images/skill-icons/postgresql.png";
import RedisSrc from "../../assets/images/skill-icons/redis.png";
import KafkaSrc from "../../assets/images/skill-icons/kafka.png";
import SapHanaSrc from "../../assets/images/skill-icons/sap.png";
import RestAPISrc from "../../assets/images/skill-icons/rest-api.png";
import GraphQLSrc from "../../assets/images/skill-icons/graphql.png";
import WebGlSrc from "../../assets/images/skill-icons/webgl.png";
import ThreeSrc from "../../assets/images/skill-icons/threejs.png";
import DockerSrc from "../../assets/images/skill-icons/docker.png";
import KubernetesSrc from "../../assets/images/skill-icons/k8s.png";
import GithubsSrc from "../../assets/images/skill-icons/github.png";
import GitlabSrc from "../../assets/images/skill-icons/gitlab.png";
import PodmanSrc from "../../assets/images/skill-icons/podman.png";

type AboutData = {
  [key: string]: {
    subcategory: string;
    iconSrc: {
      src: string;
      alt: string;
      title: string;
    }[];
    description?: string;
  }[];
};

export const aboutData: AboutData = {
  frontend: [
    {
      subcategory: "Frontend Languages",
      iconSrc: [
        {
          src: JavascriptSrc,
          alt: "javascript",
          title: "Javascript",
        },
        {
          src: TypescriptSrc,
          alt: "typescript",
          title: "Typescript",
        },
        {
          src: HtmlSrc,
          alt: "html",
          title: "HTML 5",
        },
        {
          src: CssSrc,
          alt: "css",
          title: "CSS",
        },
      ],
    },
    {
      subcategory: "Frontend Frameworks",
      iconSrc: [
        {
          src: ReactSrc,
          alt: "react",
          title: "React",
        },
        {
          src: AngularSrc,
          alt: "angular",
          title: "Angular 2+",
        },
      ],
    },
    {
      subcategory: "Web 3D Graphics And Animations",
      iconSrc: [
        {
          src: WebGlSrc,
          alt: "webgl",
          title: "Web GL",
        },
        {
          src: ThreeSrc,
          alt: "threejs",
          title: "ThreeJS",
        },
      ],
      description:
        "In addition, I also develop Mobile Applications in my spare time. However it's not my main focus for now.",
    },
  ],

  backend: [
    {
      subcategory: "Server-Side Languages",
      iconSrc: [
        {
          src: JavaSrc,
          alt: "java",
          title: "Java",
        },
        {
          src: PythonSrc,
          alt: "python",
          title: "Python",
        },
        {
          src: NodejsSrc,
          alt: "nodejs",
          title: "Node.js",
        },
        {
          src: GolangSrc,
          alt: "golang",
          title: "Go",
        },
      ],
    },
    {
      subcategory: "Backend Frameworks",
      iconSrc: [
        {
          src: ExpressSrc,
          alt: "nodejs-express",
          title: "NodeJS Express",
        },
        {
          src: SpringBootSrc,
          alt: "spring-boot",
          title: "Java/Kotlin Spring Boot",
        },
        {
          src: FastAPISrc,
          alt: "fastApi",
          title: "Python FastAPI",
        },
      ],
    },
    {
      subcategory: "Data Management",
      iconSrc: [
        {
          src: SapHanaSrc,
          alt: "sap-hana",
          title: "SAP Hana",
        },
        {
          src: PostgresSrc,
          alt: "postgresql",
          title: "PostgreSQL",
        },
        {
          src: MongoDbSrc,
          alt: "mongodb",
          title: "MongoDB",
        },
        {
          src: RedisSrc,
          alt: "redis",
          title: "Redis",
        },
        {
          src: KafkaSrc,
          alt: "kafka",
          title: "Kafka",
        },
      ],
    },
    {
      subcategory: "API Development",
      iconSrc: [
        {
          src: RestAPISrc,
          alt: "rest-api",
          title: "REST API",
        },
        {
          src: GraphQLSrc,
          alt: "graphql",
          title: "GraphQL",
        },
      ],
    },
  ],

  devops: [
    {
      subcategory: "Containerization and Virtualization",
      iconSrc: [
        {
          src: DockerSrc,
          alt: "docker",
          title: "Docker",
        },
        {
          src: PodmanSrc,
          alt: "podman",
          title: "Podman",
        },
        {
          src: KubernetesSrc,
          alt: "kubernetes",
          title: "Kubernetes",
        },
      ],
    },
    {
      subcategory: "CI/CD",
      iconSrc: [
        {
          src: GithubsSrc,
          alt: "github-actions",
          title: "Github Actions",
        },
        {
          src: GitlabSrc,
          alt: "gitlab-ci",
          title: "Gitlab CI",
        },
      ],
      description:
        "Also familiar with Jenkins and CircleCI. Additionally, I have experience with AWS, GCP, and Azure.",
    },
  ],

  personal: [
    {
      subcategory: "Leadership",
      iconSrc: [],
      description:
        "Demonstrated through the ability to mentor juniors and lead projects, crucial for guiding teams and driving projects to successful completion.",
    },
    {
      subcategory: "Team Collaboration",
      iconSrc: [],
      description:
        "Proven by the experience working in diverse teams, highlighting the ability to work effectively with different personalities and skill sets.",
    },
    {
      subcategory: "Problem Solving",
      iconSrc: [],
      description:
        "Critical for identifying and resolving issues, ensuring the smooth operation of applications and systems.",
    },
    {
      subcategory: "Adaptability",
      iconSrc: [],
      description:
        "Shown through the ability to quickly learn new technologies and adapt to new environments, essential for keeping up with the fast-paced tech industry.",
    },
  ],
};
