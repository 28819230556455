import "./Desktop.scss";
import WindowManager from "./WindowManager/WindowManager";
import MenuBar from "./MenuBar/MenuBar";
import QUOTES from "./welcomeMessageQuotes";

const Desktop = () => {
  const quote = QUOTES[Math.floor(Math.random() * QUOTES.length)];

  return (
    <>
      <div className="personal-website">
        <MenuBar />
        <WindowManager />
      </div>
      <div className="welcome-screen">
        <div className="welcome-screen__message">
          <h1 className="welcome-screen__message__title">{quote.title}</h1>
          <p className="welcome-screen__message__subtitle">{quote.subtitle}</p>
          <div className="welcome-screen__loader"></div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
